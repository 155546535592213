import React, {useState} from 'react';
import axios from 'axios';
import { Card, Stack, Col, Button, Modal } from 'react-bootstrap';

const ArchivedCell = ({item}) => {

    const [showModal, setShowModal] = useState(false)

    const handleReinstateClick = async () => {
        try {
            const response = await axios.post('https://oldhouseparts.com/reinstate.php', item, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            console.log(response.data);
            alert('Inventory item was successfully reinstated');
            window.location.reload();
        } catch (error) {
            console.error('Error:', error);
            alert('Form data is invalid. Please check the fields.');
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    }

  return (
    <div className='edit-inventory-cell py-5 px-4'>
            <Card>
                <Card.Img variant="top" src={`https://www.oldhouseparts.com/${item.image_path1}`} />
                <Card.Body>
                    <Card.Text className='cell-text'>
                    <Stack className='justify-content-between d-flex' direction="horizontal">
                            <div className="inventory-name-text pe-1"><strong>{item.name}</strong></div>
                            <div>ID: #{item.id}</div>
                        </Stack>
                        <Col>
                            <div className="date"><strong>Created:</strong> {item.date.split(/(\s+)/)[0]}</div>
                            <div className="date"><strong>Modified:</strong> {item.date_modified}</div>
                            </Col>
                    </Card.Text>
                </Card.Body>
                <Col className='cell-btns pt-2'>
                    <Button onClick={handleOpenModal}>Reinstate</Button>
                </Col>
            </Card>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Reinstate Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to reinstate this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant='danger' onClick={handleReinstateClick}>
                        Reinstate
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
  )
}

export default ArchivedCell
