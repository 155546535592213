import './App.css';
import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { Login, AddDashboard, EditDashboard } from "./containers";
import { DashboardNav } from './components';
import { Container } from 'react-bootstrap';

function App() {
  const [isAddDashboard, setIsAddDashboard] = useState(true);

  const handleDashboardChange = (isAdd) => {
    setIsAddDashboard(isAdd);
  };


  return (
    <div className="App">
      <Container className='my-5 p-5'>
        <DashboardNav className='my-4' onDashboardChange={handleDashboardChange} />
        <div>
          {isAddDashboard ? <AddDashboard /> : <EditDashboard />}
        </div>
      </Container>
    </div>
  );
}

export default App;
