import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./editmodal.css";
import { Modal, Button, Form, Image, Col, Row } from 'react-bootstrap';

const EditModal = ({ item, show, onHide }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ ...item });
    const [subCategories, setSubCategories] = useState([]);
    const [imagePreviews, setImagePreviews] = useState({
        image1: null,
        image2: null,
        image3: null,
    });

    const handleSubCategoryChange = (category) => {
        switch (category) {
            case 'antiques':
                setSubCategories(['Antique']);
                break;
            case 'doors':
                setSubCategories(['Exterior', 'Interior', 'Screen and Storm']);
                break;
            case 'windows':
                setSubCategories(['Stained and Leaded', 'Other']);
                break;
            case 'lighting':
                setSubCategories(['Flush and Semi-Flush', 'Hanging', 'Sconces', 'Shades and Globes', 'Other']);
                break;
            case 'plumbing':
                setSubCategories(['Bathtubs', 'Sinks', 'Accessories', 'Fixtures']);
                break;
            case 'hardware':
                setSubCategories(['Knobs and Locksets', 'Door Knockers', 'Escutcheons', 'Pulls and Handles', 'Other']);
                break;
            case 'other':
                setSubCategories(['Architectural Elements', 'Heat and Fireplace', 'Stone, Garden, and Masonry', 'Newel Posts and Stair Parts']);
                break;
            default:
                setSubCategories([]);
        }
    };

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        handleSubCategoryChange(selectedCategory);

        setFormData({
            ...formData,
            category: selectedCategory
        });
    };

    const handleSubCategorySelectChange = (e) => {
        const selectedSubCategory = e.target.value
        setFormData({
            ...formData,
            sub_category: selectedSubCategory,
        });
    };

    const handleFileInputChange = (e, index) => {
        const file = e.target.files[0];
        const imageDisplay = URL.createObjectURL(file);

        setImagePreviews(prevImagePreviews => ({
            ...prevImagePreviews,
            [index]: imageDisplay,
        }));

        setFormData({
            ...formData,
            [index]: file
        });
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? (checked ? "1" : "0") : e.target.value;
        setFormData({
            ...formData,
            [name]: newValue,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log("formData on submit", formData)

        try {
            const response = await axios.post('https://www.oldhouseparts.com/revise.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response.data);
            alert('Inventory item was successfully edited');
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
            alert('Form data is invalid. Please check the fields.');
            setLoading(false);
        }
    };
    
    const clearImage = (imageNumber) => {
        
        const imagePreviewName = `image${imageNumber}`
        setImagePreviews(prevState => ({
            ...prevState,
            [imagePreviewName]: null 
        }));

        const path = `image_path${imageNumber}`
        setFormData({
            ...formData,
            [path]: ""
        });
        
        console.log("button clicked- path", "Image Path", path, "Image Preview", imagePreviews, "formData", formData)
    };

    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            sub_category: subCategories.length > 0 ? subCategories[0] : prevFormData.sub_category
        }));

        handleSubCategoryChange(formData.category);
    }, [formData.category]);

    return (
        <>

            <Modal className='edit-modal' show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div>
                            <strong>{item.name}</strong>
                            <span className='id-display ps-3'>ID: #{item.id}</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading &&
                        <div className='placeholder-zone-loading d-flex justify-content-center'>
                            <div className='spinner '></div>
                        </div>}
                    {!loading &&
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Row>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter New Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Width</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    step="0.0001"
                                                    min="0"
                                                    placeholder="Enter New Width"
                                                    name="dimensionW"
                                                    value={formData.dimensionW}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Height</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    step="0.0001"
                                                    min="0"
                                                    placeholder="Enter New Height"
                                                    name="dimensionH"
                                                    value={formData.dimensionH}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Depth</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    step="0.0001"
                                                    min="0"
                                                    placeholder="Enter New Depth"
                                                    name="dimensionD"
                                                    value={formData.dimensionD}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Price</Form.Label>
                                            <Form.Control
                                                type="number"
                                                step="0.01"
                                                min="0"
                                                placeholder="Enter New Price"
                                                name="price"
                                                value={formData.price}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Quantity</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter New Quantity"
                                                name="quantity"
                                                value={formData.quantity}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter New Description"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Category</Form.Label>
                                            <Form.Select
                                                name='category'
                                                value={formData.category}
                                                onChange={handleCategoryChange}
                                            >
                                                <option>Select</option>
                                                <option value="antiques">Antiques</option>
                                                <option value="doors">Doors</option>
                                                <option value="windows">Windows</option>
                                                <option value="lighting">Lighting</option>
                                                <option value="plumbing">Plumbing</option>
                                                <option value="hardware">Hardware</option>
                                                <option value="other">Other</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Subcategory</Form.Label>
                                            <Form.Select
                                                name="sub_category"
                                                value={formData.sub_category}
                                                onChange={handleSubCategorySelectChange}
                                            >
                                                {subCategories.map((sub_category, index) => (
                                                    <option key={index} value={sub_category}>
                                                        {sub_category}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Door Panels</Form.Label>
                                            <Form.Select
                                                name="doorPanels"
                                                onChange={handleChange}
                                                value={formData.door_panels}
                                                disabled={formData.category !== 'doors'}
                                            >
                                                <option>Select</option>
                                                <option value={1}>
                                                    One
                                                </option>
                                                <option value={2}>
                                                    Two
                                                </option>
                                                <option value={3}>
                                                    Three
                                                </option>
                                                <option value={4}>
                                                    Four
                                                </option>
                                                <option value={5}>
                                                    Five
                                                </option>
                                                <option value={6}>
                                                    Six
                                                </option>
                                                <option value={7}>
                                                    Seven +
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group className="my-3 ps-3" >
                                            <Form.Check
                                                type="checkbox"
                                                label="Shipping"
                                                name="shipping"
                                                checked={formData.shipping === "1"}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Row>
                                </Col>

                                <Col>
                                    <Row>
                                        <Form.Group controlId="formImage1">
                                            <Form.Label>Image 1</Form.Label>
                                            <br />
                                            {imagePreviews.image1 ? (
                                                <Image src={imagePreviews.image1} width={300} height={200} />
                                            ) : (
                                                <Image src={`https://www.oldhouseparts.com/${formData.image_path1}`} width={300} height={200} />
                                            )}
                                            <Form.Control
                                                type="file"
                                                name="image1"
                                                accept="image/*"
                                                onChange={(e) => handleFileInputChange(e, 'image1')}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Row>
                                        <Form.Group controlId="formImage2">
                                            <Form.Label>Image 2</Form.Label>
                                            <br />
                                            {imagePreviews.image2 ? (
                                                <Image src={imagePreviews.image2} width={300} height={200} />
                                            ) : (
                                                <Image src={`https://www.oldhouseparts.com/${formData.image_path2}`} width={300} height={200} />
                                            )}
                                            <Form.Control
                                                type="file"
                                                name="image2"
                                                accept="image/*"
                                                onChange={(e) => handleFileInputChange(e, 'image2')}
                                            />
                                            <Button className='my-2' variant="secondary" onClick={() => clearImage("2")}>Clear Image</Button>
                                        </Form.Group>

                                    </Row>
                                    <Row>
                                        <Form.Group controlId="formImage3">
                                            <Form.Label>Image 3</Form.Label>
                                            <br />
                                            {imagePreviews.image3 ? (
                                                <Image src={imagePreviews.image3} width={300} height={200} />
                                            ) : (
                                                <Image src={`https://www.oldhouseparts.com/${formData.image_path3}`} width={300} height={200} />
                                            )}
                                            <Form.Control
                                                type="file"
                                                name="image3"
                                                accept="image/*"
                                                onChange={(e) => handleFileInputChange(e, 'image3')}
                                            />
                                            <Button className='my-2' variant="secondary" onClick={() => clearImage("3")}>Clear Image</Button>
                                        </Form.Group>
                                    </Row>
                                </Col>
                            </Row>
                            <Modal.Footer>
                                <Button type="submit" variant='primary'>
                                    Confirm Edits
                                </Button>
                            </Modal.Footer>
                        </Form>
                    }
                </Modal.Body>
            </Modal>

        </>
    );
}

export default EditModal;
